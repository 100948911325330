<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">端末登録</ion-card-title>
        </ion-card-header>
      <ion-card-content>
        <div class="conte">
            <ion-label position="floating">電話番号</ion-label>
            <ion-input type="tel" id="inpcont" maxlength="15" v-model="phoneNum"></ion-input>
            <ion-text color="danger" v-show="errMsg.phone!=''">{{ errMsg.phone }}</ion-text>
        </div>
        <div class="conte">
            <ion-label position="floating">認証コード</ion-label>
            <ion-input type="email" id="inpcont" v-model="autheCode"></ion-input>
            <ion-text color="danger" v-show="errMsg.authe!=''">{{ errMsg.authe }}</ion-text>
        </div>
        <div class="conte">
            <ion-text color="danger" v-show="errMsg.msg!=''" style="white-space: pre;">{{ errMsg.msg }}</ion-text>
        </div> 
        <ion-button id="next" color="primary" expand="block" @click="clickKakutei()">登録</ion-button>
        
      </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import {
  IonContent, IonPage, IonCardTitle, IonCard, IonButton,
  IonInput, IonCardHeader, IonCardContent, IonLabel, IonText, alertController,
  } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Phonenum',
  components: {
    IonContent, IonPage, IonCardTitle, IonCard, IonButton,
    IonInput, IonCardHeader, IonCardContent, IonLabel,IonText
  },
  data(){
      return {
        phoneNum:'',
        autheCode:'',
        errMsg:{
          phone:'',
          authe:'',
          msg:'',
          status:''
          },
      };
  },
  methods: {
    // 登録完了画面へ遷移
    async clickKakutei() {
        //エラーコード初期化
        this.errMsg.phone=''
        this.errMsg.authe=''
        this.errMsg.msg=''
        this.errMsg.status=''

      this.accessDate = this.get_now_ymd();

          //変数の空欄チェック
        if(!this.phoneNum){
          this.errMsg.phone = '電話番号'
        }
        if(!this.autheCode){
          this.errMsg.authe = '認証コード'
        }

        if(this.errMsg.phone || this.errMsg.authe){
          const msg = 'が記入されておりません。'
          this.errMsg.phone = this.errMsg.phone + (this.errMsg.phone != '' ? msg : '')
          this.errMsg.authe = this.errMsg.authe + (this.errMsg.authe != '' ? msg : '')
          return
        }
        //ハイフンチェック
        if (this.phoneNum.match(/[-ー]/)){
          this.errMsg.msg = 'ハイフンなしで入力してください。'
          return
        }
        //半角数値のみ抽出
        let phoneNewNum=this.phoneNum.replace(/[^0-9]/g,"")
        //電話番号の形式(ハイフンなし9-11桁(0を含む))に合っていなければエラー
        if(!phoneNewNum.match(/^0\d{8,10}$/)){
          this.errMsg.msg = '電話番号の形式に誤りがあります。\n      もう一度内容をご確認ください。'
          return
        }

      //入力された電話番号、認証コードを登録
      const res = await this.axios.post('genba-signup', {
        'phone': phoneNewNum,
        'authe_cd': this.autheCode,
        'access_date': this.accessDate
      })
      if(res.data.status=='1'){
        this.errMsg.msg = '認証コードが存在しません。改めて確認ください。'
        return
      }
      if(res.data.status=='2'){
        this.errMsg.msg = '認証コードの有効期限が切れています。'
        return
      }
      if(res.data.status=='3'){
        this.presentAlert("登録済みの認証コードです。システム利用認証画面からご利用ください。");
        return
      }


      const deviceCd = res.data.deviceCd
      localStorage.setItem('genba_device_cd', deviceCd);
      this.setManifestURL(deviceCd);
      this.$router.push({name:'Toroku'});
    },
    presentAlert: async function (msg) {
      const alert = await alertController.create({
        header: 'エラー',
        message: msg,
        buttons: [
          {
            text: 'システム利用認証へ',
            role: 'confirm',
            handler: () => {
              this.$router.push('/login');
            },
          },
          {
            text: '閉じる',
            role: 'cancel',
            // handler: () => {
            //   this.handlerMessage = 'Alert canceled';
            // },
          },
        ]
      });

      await alert.present();
    },
    // 現在の日時を取得
    get_now_ymd: function () {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth()+1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + "/" + m + "/" + d;
        return result;
    },
    // pwa用のmanifestを生成
    setManifestURL: function (token) {
      var baseUrl = 'https://'+ document.domain;
      var startUrl = token ? baseUrl + '?device=' + token : baseUrl;
      console.log('startUrl: '+ startUrl)

      var manifest = {
        "name": "Web-Container",
        "short_name": "モバイル作業者システム",
        "icons": [
            {
                "src": baseUrl + "/img/icons/sms-icon-192x192.png",
                "sizes": "192x192",
                "type": "image/png"
            },
            {
                "src": baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                "sizes": "512x512",
                "type": "image/png"
            },
            {
                "src": baseUrl + "/img/icons/sms-icon-bgw-192x192.png",
                "sizes": "192x192",
                "type": "image/png",
                "purpose": "maskable"
            },
            {
                "src": baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                "sizes": "512x512",
                "type": "image/png",
                "purpose": "maskable"
            }
        ],
        "start_url": startUrl,
        "display": "standalone",
        "background_color": "white",
        "theme_color": "#4DBA87"
      }

      const stringManifest = JSON.stringify(manifest);
      const blob = new Blob([stringManifest], { type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#my-manifest').setAttribute('href', manifestURL);
    },
  }
});
</script>

<style scoped>
#next {
  margin:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
#tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
.conte {
  margin: 20px;
}
#inpcont {
  border: solid 0.5px;
  border-color: #ddd;
  border-radius: 5px;
}
</style>